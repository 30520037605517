// App.js
import React, { Suspense, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation,matchPath } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/style.css';
import './assets/css/main.css';
import Nav from "./component/nav/nav";
import Home from './component/home/home';
import Footer from './component/footer/footer';
import Jobs from './component/jobs/jobs';
import JobDetails from './component/jobs/jobDetails';
import ApplyJobForm from './component/jobs/applyJobForm';
import Login from './component/login/login';
import OTP from './component/login/otp';
import Register from './component/login/register';
import Profile from './component/profile/profile';
import SavedJobs from './component/jobs/savedJobs';
import AppliedJobs from './component/jobs/appliedJobs';
import Immigration from './component/otherPages/immigration';
import ImmigrationDetails from './component/otherPages/immigrationDetails';
import About from './component/otherPages/about';
import Service from './component/otherPages/service';
import Blog from './component/otherPages/blog';
import InnerBlog from './component/otherPages/innerBlog';
import Testimonials from './component/otherPages/testimonials';
import GalleryPage from './component/otherPages/gallery';
import Contact from './component/otherPages/contact';
import InnerService from './component/otherPages/innerService';
import Pagenotfound from './component/otherPages/404';
import Faq from './component/otherPages/faq';
import PrivacyPollicy from './component/otherPages/privacyPollicy';
import TermsConditions from './component/otherPages/termsConditions';
import InnerImmigrationService from './component/otherPages/innerImmigrationService';
import ShippingAndDelivery from './component/otherPages/ShippingAndDelivery';
import Logintest from './component/login/logintest';
import { LoginPopupContext } from './component/loginpopup/LoginPopupContext';
import CancellationRefundPolicy from './component/otherPages/CancellationAndRefund';
import { SearchProvider } from './component/searchcontext/SearchContexts';
import ScrollToTop from './component/srollTop';
import { GlobalProvider } from './component/globalProvider/globalProvider'; // Import your GlobalProvider

const path = "";

function App() {
  const [isLoginPopupVisible, setLoginPopupVisible] = useState(false);
  const location = useLocation();
  const is404Page = location.pathname === `${path}/404`;

  const validPaths = [
    `${path}/`, 
    `${path}/jobs`, 
    `${path}/jobDetails/:id`, 
    `${path}/applyJobForm/:id`,
    `${path}/login`, 
    `${path}/otp`, 
    `${path}/register`, 
    `${path}/profile`, 
    `${path}/savedJobs`, 
    `${path}/appliedJobs`, 
    `${path}/immigration`, 
    `${path}/immigrationDetails/:id`, 
    `${path}/about`, 
    `${path}/service`, 
    `${path}/blog`, 
    `${path}/:blog_title/:id`, 
    `${path}/testimonials`, 
    `${path}/gallery`, 
    `${path}/contact`, 
    `${path}/service/:service_name_format/:id`, 
    `${path}/faq`, 
    `${path}/privacyPollicy`, 
    `${path}/termsConditions`, 
    `${path}/InnerImmigrationService/:id`, 
    `${path}/CancellationAndRefund`, 
    `${path}/ShippingAndDelivery`, 
    `${path}/logintest`, 
    `${path}/404`
  ];
  
 
  // Check if the current path matches any of the valid paths
  const isValidPath = validPaths.some(validPath => matchPath(validPath, location.pathname));
  const isInvalidPath = !isValidPath && !is404Page;
  return (
    <Suspense
      fallback={
        <div className="pageLoading">
          <div className="spinLoader"></div>
        </div>
      }
    >
      <SearchProvider>
        <LoginPopupContext.Provider value={[isLoginPopupVisible, setLoginPopupVisible]}>
          <div className="app">
            <div className="shimmer"></div>
            <ToastContainer />
            {!isInvalidPath && <Nav />}
            <ScrollToTop />
            <Routes>
              <Route path={`${path}/`} element={<Home />} />
              <Route path={`${path}/jobs`} element={<Jobs />} />
              <Route path={`${path}/jobDetails/:id`} element={<JobDetails />} />
              <Route path={`${path}/applyJobForm/:id`} element={<ApplyJobForm />} />
              <Route path={`${path}/login`} element={<Login />} />
              <Route path={`${path}/otp`} element={<OTP />} />
              <Route path={`${path}/register`} element={<Register />} />
              <Route path={`${path}/profile`} element={<Profile />} />
              <Route path={`${path}/savedJobs`} element={<SavedJobs />} />
              <Route path={`${path}/appliedJobs`} element={<AppliedJobs />} />
              <Route path={`${path}/immigration`} element={<Immigration />} />
              <Route path={`${path}/immigrationDetails/:id`} element={<ImmigrationDetails />} />
              <Route path={`${path}/about`} element={<About />} />
              <Route path={`${path}/service`} element={<Service />} />
              <Route path={`${path}/blog`} element={<Blog />} />
              <Route path={`${path}/:blog_title/:id`} element={<InnerBlog />} />
              <Route path={`${path}/testimonials`} element={<Testimonials />} />
              <Route path={`${path}/gallery`} element={<GalleryPage />} />
              <Route path={`${path}/contact`} element={<Contact />} />
              <Route path={`${path}/service/:service_name_format/:id`} element={<InnerService />} />
              <Route path={`${path}/faq`} element={<Faq />} />
              <Route path={`${path}/privacyPollicy`} element={<PrivacyPollicy />} />
              <Route path={`${path}/termsConditions`} element={<TermsConditions />} />
              <Route path={`${path}/InnerImmigrationService/:id`} element={<InnerImmigrationService />} />
              <Route path={`${path}/CancellationAndRefund`} element={<CancellationRefundPolicy />} />
              <Route path={`${path}/ShippingAndDelivery`} element={<ShippingAndDelivery />} />
              <Route path={`${path}/logintest`} element={<Logintest />} />
              <Route path="*" element={<Pagenotfound />} />
            </Routes>
            {isValidPath && <Footer />}
          </div>
        </LoginPopupContext.Provider>
      </SearchProvider>
    </Suspense>
  );
}

// Wrap the entire App with GlobalProvider
function AppWithProvider() {
  return (
    <Router>
      <GlobalProvider>  {/* Make sure GlobalProvider wraps everything */}
        <App />
      </GlobalProvider>
    </Router>
  );
}

export default AppWithProvider;
