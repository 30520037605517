import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// export const baseURL='http://127.0.0.1:8002/api';
// export const mediaUrl = 'http://127.0.0.1:8002/';
// export const mediaUrl = 'https://danielsjobfinder.com/demo3/public/';
// export const baseURL='https://danielsjobfinder.com/demo3/api';

const getBaseUrl = () => {
    const currentHost = window.location.hostname; // Get the current domain name
    return currentHost.startsWith('www.') ? 'https://www.danielsjobfinder.com' : 'https://danielsjobfinder.com';
  }
  
  // Dynamically construct the URLs based on whether 'www' is present or not
  const baseUrl = getBaseUrl();
  
  export const mediaUrl = `${baseUrl}/demo3/public/`;
  export const baseURL = `${baseUrl}/demo3/api`;
  
  console.log('Media URL:', mediaUrl);
  console.log('Base URL:', baseURL);
  

const axiosInstance = axios.create({
    baseURL: baseURL,
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Session expired or invalid session
            useNavigate('login');
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
